import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from '@mui/material';

const ExportExcel = forwardRef(({ excelData, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);

    }

    return (
        
            <Button
                onClick={(e) => exportToExcel(fileName)}
            >Descargar Excel</Button>
        
    );
});

ExportExcel.defaultProps = {
    excelData: [],
    fileName: ''
  };

  ExportExcel.propTypes = {
    excelData: PropTypes.arrayOf(PropTypes.object),
    fileName: PropTypes.string,
  };

export default ExportExcel;