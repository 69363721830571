import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const getAllInvoices = async (page = 1) => {
  try {
    const response = await fetch(`${BASE_API_URL}invoices?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const cancelInvoice = async (id) => {
  try {
    const response = await fetch(`${BASE_API_URL}invoices/${id}/cancel`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
