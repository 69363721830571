import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const getAllCollaborators = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}collaborators`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const editCollaborator = async (email, json) => {
  try {
    const response = await fetch(`${BASE_API_URL}collaborators/${email}`, {
      method: "PATCH",
      body: json,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const deleteCollaborator = async (email) => {
  try {
    const response = await fetch(`${BASE_API_URL}collaborators/${email}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const createCollaborator = async (name, email, password, marketId) => {
  try {
    const response = await fetch(`${BASE_API_URL}collaborators`, {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        password,
        marketId: Number(marketId),
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
