import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const getAllProducts = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const editProduct = async (id, json) => {
  try {
    const response = await fetch(`${BASE_API_URL}products/${id}`, {
      method: "PATCH",
      body: json,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await fetch(`${BASE_API_URL}products/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const createProduct = async (name, price, marketId) => {
  try {
    const response = await fetch(`${BASE_API_URL}products`, {
      method: "POST",
      body: JSON.stringify({
        name,
        price,
        marketId: Number(marketId),
        active: true,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
