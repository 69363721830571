import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const getAllPurchases = async (params) => {
  let paramsValues = "";
  if (params !== null || params !== undefined) {
    Object.keys(params).forEach((k, i) => {
      if (i > 0) {
        paramsValues += "&";
      }
      paramsValues += `${k}=${params[k]}`;
    });
  }
  try {
    const response = await fetch(
      `${BASE_API_URL}purchases${paramsValues !== "" ? `?${paramsValues}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("session")}`,
        },
      }
    );

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
