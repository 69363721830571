import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { Button, FormControl } from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  deleteCollaborator,
  getAllCollaborators,
  editCollaborator,
  createCollaborator,
} from "services/collaborators-service";
import { getAllEvents } from "services/event-service";
import TextField from "@mui/material/TextField";
import {
  deleteProduct,
  createProduct,
  getAllProducts,
  editProduct,
} from "services/products-service";

function Products() {
  const [currentProduct, setCurrentProduct] = useState({});
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setEvents] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreateNew, setOpenCreateNew] = useState(false);
  const handleOpenCreateNew = () => setOpenCreateNew(true);
  const handleCloseCreateNew = () => setOpenCreateNew(false);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [eventId, setEventId] = useState("");
  const [marketId, setMarketId] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  const columns = [
    { Header: "Nombre", accessor: "name", align: "left" },
    { Header: "Precio", accessor: "price", align: "left" },
    { Header: "Evento", accessor: "event", align: "left" },
    { Header: "Tienda", accessor: "market", align: "left" },
    { Header: "Opciones", accessor: "options", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  const changeMarkets = (value) => {
    setEventId(value);
    const eventSelected = events.find((e) => e.id === Number(value));

    if (eventSelected) {
      setMarkets(eventSelected.markets);
    }
  };

  const onEditProduct = async () => {
    try {
      await editProduct(
        currentProduct.id,
        JSON.stringify({
          name,
          price,
          marketId,
        })
      );

      handleCloseCreateNew();

      const response = await getAllProducts();

      const currentRows = [];
      response.data.forEach((products) => {
        currentRows.push({
          name: <p>{products.name}</p>,
          price: <p>Q.{parseFloat(products.price).toFixed(2)}</p>,
          event: <p>{products.market.event.name}</p>,
          market: <p>{products.market.name}</p>,
          options: (
            <MDBox>
              <IconButton
                onClick={() => {
                  setCurrentProduct(products);
                  setName(products.name);
                  setPrice(Number(products.price));
                  setEventId(products.market.event.id);
                  changeMarkets(products.market.event.id);
                  setMarketId(products.market.id);
                  setIsEditing(true);
                  handleOpenCreateNew();
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  setCurrentProduct(products);
                  handleOpen();
                }}
              >
                <Delete color="error" />
              </IconButton>
            </MDBox>
          ),
        });
      });
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const getEvents = async () => {
    try {
      const response = await getAllEvents();

      setEvents(response.data);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const getData = async () => {
    try {
      const response = await getAllProducts();

      const currentRows = [];
      response.data.forEach((products) => {
        currentRows.push({
          name: <p>{products.name}</p>,
          price: <p>Q.{parseFloat(products.price).toFixed(2)}</p>,
          event: <p>{products.market.event.name}</p>,
          market: <p>{products.market.name}</p>,
          options: (
            <MDBox>
              <IconButton
                onClick={() => {
                  setCurrentProduct(products);
                  setName(products.name);
                  setPrice(Number(products.price));
                  setEventId(products.market.event.id);
                  changeMarkets(products.market.event.id);
                  setMarketId(products.market.id);
                  setIsEditing(true);
                  handleOpenCreateNew();
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  setCurrentProduct(products);
                  handleOpen();
                }}
              >
                <Delete color="error" />
              </IconButton>
            </MDBox>
          ),
        });
      });
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const onDeleteProduct = async () => {
    try {
      const response = await deleteProduct(currentProduct.id);

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Producto creado"
      content="Se ha creado un nuevo producto"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Ha ocurrido un error"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(async () => {
    getData();
    getEvents();
  }, []);

  const createNewProduct = async () => {
    if (name !== "" && price !== "" && marketId !== "") {
      handleCloseCreateNew();
      try {
        const response = await createProduct(name, price, marketId);
        setName("");
        setPrice("");
        setMarketId("");
        openSuccessSB();
        getData();
      } catch (e) {
        if (e.message !== null) {
          setMessage(e.message);
        } else {
          setMessage("Error en el servidor");
        }
        openErrorSB();
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Producto</MDTypography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              setIsEditing(false);
              handleOpenCreateNew();
            }}
          >
            Crear nuevo
          </Button>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  entriesPerPage={false}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Eliminar Producto
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Se eliminará toda la información del producto. Pero permanecerá guardado en el reporte
            de ventas.
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onDeleteProduct();
                handleClose();
              }}
            >
              Eliminar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={openCreateNew}
        onClose={handleCloseCreateNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            {isEditing ? "Editar" : "Crear"} Producto
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setName(e.target.value)}
            >
              <TextField id="outlined-basic" value={name} label="Nombre" variant="outlined" />
            </FormControl>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setPrice(e.target.value)}
            >
              <TextField
                id="outlined-basic"
                type="number"
                value={price}
                label="Precio"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
              <TextField
                id="events"
                select
                defaultValue="event-"
                onChange={(e) => changeMarkets(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                value={eventId !== "" ? eventId : ""}
                helperText="Evento al que pertenecerá el collaborador"
              >
                <option key="event-" value="">
                  Selecciona un evento
                </option>
                {events.map((option) => (
                  <option key={`event-${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
              <TextField
                id="markets"
                select
                defaultValue="market-"
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setMarketId(e.target.value)}
                value={marketId}
                helperText="Tienda donde realizará los cobros"
              >
                <option key="market-" value="">
                  Selecciona una tienda
                </option>
                {markets.map((option) => (
                  <option key={`market-${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setName("");
                setPrice("");
                setEventId("");
                setMarketId("");
                handleCloseCreateNew();
              }}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              onClick={() => (isEditing ? onEditProduct() : createNewProduct())}
            >
              {isEditing ? "Editar" : "Crear"}
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      {renderErrorSB}
      {renderSuccessSB}
    </DashboardLayout>
  );
}

export default Products;
