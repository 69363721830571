// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import { Box } from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { useState } from "react";
import { recharge } from "services/recharge-service";

function Payment() {
  const [controller, dispatch] = useMaterialUIController();
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExp, setCardExp] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [amount, setAmount] = useState(0);

  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const { user } = controller;

  const validateExp = (value) => {
    if (value.length < 2) {
      setCardExp(value);
    } else if (value.length === 2) {
      if (cardExp.indexOf("/") < 0) {
        setCardExp(`${value}/`);
      } else {
        setCardExp(value);
      }
    } else if (value.length > 2 && value.length <= 5) {
      setCardExp(value);
    }
  };

  const rechargeToUser = async () => {
    if (cardNumber.length <= 16 && cardCVV.length <= 4 && amount > 0) {
      try {
        const response = await recharge(userEmail, amount, cardNumber, cardExp, cardCVV);
        setMessage(response.message);
        openSuccessSB();
        setUserEmail('');
        setAmount(0);
      } catch (e) {
        console.log(e);
        if (e.message !== null) {
          setMessage(e.message);
        } else {
          setMessage("Error en el servidor");
        }
        openErrorSB();
      }
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Recarga realizada"
      content="La recarga se realizo correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al realizar la recarga"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar absolute />
      <MDBox mt={18} mb={12}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/*<Grid container xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <Box style={{ maxWidth: "402px" }}>
                  <MasterCard
                    color="success"
                    number={cardNumber}
                    holder={cardName}
                    expires={cardExp}
                  />
                </Box>
  </Grid>*/}
              <Grid container justifyContent="center" mt={8}>
                <Grid item xs={12} md={4}>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Correo electrónico del usuario"
                      variant="standard"
                      fullWidth
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </MDBox>
                  {/*<MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Nombre de tarjeta"
                      onChange={(e) => setCardName(e.target.value)}
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      error={cardNumber.length > 16}
                      type="number"
                      inputProps={{ maxLength: 16 }}
                      label="Número de tarjeta"
                      onChange={(e) => setCardNumber(e.target.value)}
                      variant="standard"
                      fullWidth
                    />
  </MDBox>*/}
                  <Grid container>
                    {/*<Grid item xs={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Fecha de expiración"
                          value={cardExp}
                          onChange={(e) => validateExp(e.target.value)}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox mb={2}>
                        <MDInput
                          error={cardCVV.length > 4}
                          type="number"
                          label="CVV"
                          onChange={(e) => setCardCVV(e.target.value)}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </Grid>*/}
                    <Grid item xs={12} mt={4}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Monto a recargar"
                          onChange={(e) => setAmount(e.target.value)}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        onClick={() => rechargeToUser()}
                        color="primary"
                        fullWidth
                      >
                        Realizar recarga
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {renderSuccessSB}
          {renderErrorSB}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Payment;
