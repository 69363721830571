// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getAllInvoices, cancelInvoice } from "services/invoice-service";

import TablePagination from '@mui/material/TablePagination';

function Invoices() {
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setRecharges] = useState([]);
  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const columns = [
    { Header: "No. Trasacción de Recarga", accessor: "transaction", align: "left" },
    { Header: "NIT", accessor: "nit", align: "left" },
    { Header: "Nombre", accessor: "name", align: "left" },
    { Header: "UUID", accessor: "uuid", align: "left" },
    { Header: "No. Factura", accessor: "number", align: "left" },
    { Header: "Descripcion", accessor: "description", align: "left" },
    { Header: "Fecha", accessor: "date", align: "left" },
    { Header: "Opciones", accessor: "options", align: "left" },
  ];

  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState(null);

  let onCancelInvoice = async (id) => {};

  const getData = async (page = 1) => {
    try {
      const response = await getAllInvoices(page);

      setRecharges(response.data);
      setPagination(response.pagination);
      const currentRows = [];
      response.data.forEach((invoice) => {
        currentRows.push({
          transaction: <p>{invoice.Recharge[0]?.transaction || "-"}</p>,
          uuid: <p>{invoice.infileUUID}</p>,
          nit: <p>{invoice.nit || "-"}</p>,
          name: <p>{invoice.Recharge?.userName || "-"}</p>,
          number: <p>{invoice.infileNumero}</p>,
          description:
            invoice.infileDescripcion === "Validado y Certificado Exitosamente" ? (
              <MDTypography variant="p" style={{ fontWeight: "bold" }} color="success">
                {invoice.infileDescripcion}
              </MDTypography>
            ) : (
              <MDTypography variant="p" style={{ fontWeight: "bold" }} color="error">
                {invoice.infileDescripcion}
              </MDTypography>
            ),
          date: <p>{new Date(invoice.createdAt).toLocaleDateString("es-GT")}</p>,
          options:
            invoice.infileDescripcion === "Validado y Certificado Exitosamente" ? (
              <MDButton
                variant="gradient"
                onClick={() => onCancelInvoice(invoice.id)}
                color="primary"
                fullWidth
                size="small"
              >
                Anular
              </MDButton>
            ) : null,
        });
      });
      currentRows.reverse();
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  onCancelInvoice = async (id) => {
    try {
      await cancelInvoice(id);

      openSuccessSB();
      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Anulación realizada"
      content="Se ha anulado la factura correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al realizar la recarga"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
    getData(newPage+1);
  };

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Facturas Generadas</MDTypography>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Grid container spacing={6} mt={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  entriesPerPage={false}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {pagination && <TablePagination
                  component="div"
                  count={pagination.totalPages}
                  page={pagination.currentPage-1}
                  onPageChange={handleChangePage}
                  rowsPerPage={pagination.perPage}
                  rowsPerPageOptions={false}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}
                
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {renderErrorSB}
      {renderSuccessSB}
    </DashboardLayout>
  );
}

export default Invoices;
