// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { getAllPurchases } from "services/purchase-service";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";
import { getAllEvents } from "services/event-service";
import ExportExcel from "components/ExcelExport";

function Purchases() {
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setEvents] = useState([]);
  const [recharges, setRecharges] = useState([]);
  const [message, setMessage] = useState("");
  const [exportData, setExportData] = useState([]);

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [eventId, setEventId] = useState(0);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const columns = [
    { Header: "Producto", accessor: "product", align: "left" },
    { Header: "Cantidad", accessor: "count", align: "left" },
    { Header: "Monto", accessor: "amount", align: "left" },
    { Header: "Usuario", accessor: "user", align: "left" },
    { Header: "Cobrado por", accessor: "collaborator", align: "left" },
    { Header: "Fecha", accessor: "date", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  const getData = async (id) => {
    setEventId(id);
    try {
      const response = await getAllPurchases({ eventId: id });

      const currentRows = [];
      const exportRows = [];
      response.data.forEach((p) => {
        currentRows.push({
          product: <p>{p.productName}</p>,
          amount: <p>Q.{parseFloat(p.amount).toFixed(2)}</p>,
          user: <p>{!p.userEmail.includes('@') ? `Invitado - ${p.userId}` : p.userEmail}</p>,
          count: <p>{p.count}</p>,
          collaborator: <p>{p.collaboratorEmail}</p>,
          date: <p>{new Date(p.createdAt).toLocaleDateString("es-GT")}</p>,
        });
        exportRows.push({
          Producto: p.productName,
          Monto: `Q.${parseFloat(p.amount).toFixed(2)}`,
          Usuario: !p.userEmail.includes('@') ? `Invitado - ${p.userId}` : p.userEmail,
          Cantidad: p.count,
          Empleado: p.collaboratorEmail,
          Fecha: `${new Date(p.createdAt).toLocaleDateString("es-GT")}`,
        });
      });
      currentRows.reverse();
      exportRows.reverse();
      setRows(currentRows);
      setExportData(exportRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  useEffect(async () => {
    try {
      const eventsResponse = await getAllEvents();
      setEvents(eventsResponse.data);

      if (events.length > 0) {
        getData(events[0].id);
      }
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Recarga realizada"
      content="La recarga se realizo correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Ha ocurrido un error"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Compras</MDTypography>
        </Grid>
        <Grid item xs={12} mt={3}>
          <InputLabel>Evento</InputLabel>
          <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
            <TextField
              style={{ fontSize: "22px" }}
              id="events"
              select
              defaultValue="event-"
              onChange={(e) => getData(e.target.value)}
              SelectProps={{
                native: true,
              }}
              value={eventId !== "" ? eventId : ""}
            >
              <option key="event-" value="">
                Selecciona un evento
              </option>
              {events.map((option) => (
                <option key={`event-${option.id}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {eventId !== 0 ?<MDBox sx={{display: 'flex', justifyContent: 'flex-end' }} >
                <ExportExcel excelData={exportData} fileName={`${events.find(e => e.id === eventId)?.name || ''}_${new Date().toLocaleString('es-GT')}`}/>
              </MDBox> : null}
              <MDBox pt={3}>
                <DataTable
                  entriesPerPage={false}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Purchases;
