import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { Button, FormControl } from "@mui/material";
import { PersonOff } from "@mui/icons-material";
import {
  deleteCollaborator,
  getAllCollaborators,
  editCollaborator,
  createCollaborator,
} from "services/collaborators-service";
import { getAllEvents } from "services/event-service";
import TextField from "@mui/material/TextField";

function Collaborators() {
  const [currentCollaborator, setCurrentCollaborator] = useState({});
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setEvents] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreateNew, setOpenCreateNew] = useState(false);
  const handleOpenCreateNew = () => setOpenCreateNew(true);
  const handleCloseCreateNew = () => setOpenCreateNew(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [marketId, setMarketId] = useState("");

  const columns = [
    { Header: "Nombre", accessor: "name", align: "left" },
    { Header: "Tienda asignada", accessor: "market", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Activo", accessor: "active", align: "left" },
    { Header: "Opciones", accessor: "options", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  const onActiveCollaborator = async (c) => {
    try {
      await editCollaborator(
        c.email,
        JSON.stringify({
          active: !c.active,
        })
      );

      const response = await getAllCollaborators();

      const currentRows = [];
      response.data.forEach((collaborator) => {
        currentRows.push({
          name: <p>{collaborator.name}</p>,
          market: <p>{collaborator.market.name}</p>,
          email: <p>{collaborator.email}</p>,
          active:
            collaborator.active === true ? (
              <MDTypography variant="p" color="success">
                Activo
              </MDTypography>
            ) : (
              <MDTypography variant="p" color="error">
                No activo
              </MDTypography>
            ),
          options: (
            <MDBox>
              <IconButton
                onClick={() => {
                  setCurrentCollaborator(collaborator);
                  onActiveCollaborator(collaborator);
                }}
              >
                <PersonOff />
              </IconButton>
              <IconButton
                onClick={() => {
                  setCurrentCollaborator(collaborator);
                  handleOpen();
                }}
              >
                <Delete color="error" />
              </IconButton>
            </MDBox>
          ),
        });
      });
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const getEvents = async () => {
    try {
      const response = await getAllEvents();

      setEvents(response.data);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const getData = async () => {
    try {
      const response = await getAllCollaborators();

      const currentRows = [];
      response.data.forEach((collaborator) => {
        currentRows.push({
          name: <p>{collaborator.name}</p>,
          market: <p>{collaborator.market.name}</p>,
          email: <p>{collaborator.email}</p>,
          active:
            collaborator.active === true ? (
              <MDTypography variant="p" color="success">
                Activo
              </MDTypography>
            ) : (
              <MDTypography variant="p" color="error">
                No activo
              </MDTypography>
            ),
          options: (
            <MDBox>
              <IconButton
                onClick={() => {
                  setCurrentCollaborator(collaborator);
                  onActiveCollaborator(collaborator);
                }}
              >
                <PersonOff />
              </IconButton>
              <IconButton
                onClick={() => {
                  setCurrentCollaborator(collaborator);
                  handleOpen();
                }}
              >
                <Delete color="error" />
              </IconButton>
            </MDBox>
          ),
        });
      });
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const onDeleteCollaborator = async () => {
    try {
      const response = await deleteCollaborator(currentCollaborator.email);

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Collaborador creado"
      content="Se ha creado un nuevo collaborador"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Ha ocurrido un error"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const handleChange = (event) => {
    setActiveEvent(event.target.value);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(async () => {
    getData();
    getEvents();
  }, []);

  const changeMarkets = (value) => {
    const eventSelected = events.find((e) => e.id === Number(value));

    if (eventSelected) {
      setMarkets(eventSelected.markets);
    }
  };

  const createNewCollaborator = async () => {
    if (name !== "" && email !== "" && password !== "" && marketId !== "") {
      handleCloseCreateNew();
      try {
        const response = await createCollaborator(name, email, password, marketId);
        setName("");
        setEmail("");
        setPassword("");
        setMarketId("");
        openSuccessSB();
        getData();
      } catch (e) {
        if (e.message !== null) {
          setMessage(e.message);
        } else {
          setMessage("Error en el servidor");
        }
        openErrorSB();
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Colaboradores</MDTypography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="info" onClick={() => handleOpenCreateNew()}>
            Crear nuevo
          </Button>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  entriesPerPage={false}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Eliminar Colaborador
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Se eliminará toda la información del colaborador incluyendo sus ventas
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onDeleteCollaborator(currentCollaborator);
                handleClose();
              }}
            >
              Eliminar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={openCreateNew}
        onClose={handleCloseCreateNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Crear Collaborador
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setName(e.target.value)}
            >
              <TextField id="outlined-basic" label="Nombre" variant="outlined" />
            </FormControl>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setEmail(e.target.value)}
            >
              <TextField id="outlined-basic" label="Email" variant="outlined" />
            </FormControl>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setPassword(e.target.value)}
            >
              <TextField
                id="outlined-basic"
                type="password"
                label="Contraseña"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
              <TextField
                id="events"
                select
                defaultValue="event-"
                onChange={(e) => changeMarkets(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                helperText="Evento al que pertenecerá el collaborador"
              >
                <option key="event-" value="">
                  Selecciona un evento
                </option>
                {events.map((option) => (
                  <option key={`event-${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
              <TextField
                id="markets"
                select
                defaultValue="market-"
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setMarketId(e.target.value)}
                helperText="Tienda donde realizará los cobros"
              >
                <option key="market-" value="">
                  Selecciona una tienda
                </option>
                {markets.map((option) => (
                  <option key={`market-${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseCreateNew}>Cancelar</Button>
            <Button color="success" onClick={() => createNewCollaborator()}>
              Crear
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      {renderErrorSB}
      {renderSuccessSB}
    </DashboardLayout>
  );
}

export default Collaborators;
